const Title = ({ children, className }) => {
  return (
    <>
      <div
        className={`${className} flex items-center uppercase text-[1.3rem] font-bold `}
      >
        <div className="w-3 h-6 bg-[#FF9900] rounded-sm mr-3"></div>
        {children}
      </div>
    </>
  );
};

export default Title;
