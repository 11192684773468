import { Image } from "antd";

const ContactCard = ({ img, text, bg }) => {
  return (
    <>
      <div
        className={`${bg} flex flex-col items-center justify-center py-5 rounded-lg cursor-pointer hover:opacity-90 transition`}
      >
        <div className="w-10 h-10">
          <Image preview={false} src={img} alt="" width={"auto"} />
        </div>
        <p className="text-white text-[0.9rem] font-medium ">{text}</p>
      </div>
    </>
  );
};

export default ContactCard;
