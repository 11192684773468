import { Image } from "antd";
import "aos/dist/aos.css";
import "./styles.css";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";

const Footer = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 425px)" });
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      {isMobile ? (
        <div className="text-white border-t-[1px] border-white">
          <div className="overflow-hidden justify-center px-8 page-bg w-full h-[35vh] flex flex-col items-center container mx-auto">
            <div
              className="font-bold text-white text-[1.2rem] text-center"
              data-aos="fade-left"
            >
              Hãy để chúng tôi đồng hành cùng bạn trên hành trình chuyển đổi kỹ
              thuật số của bạn.
            </div>
            {location.pathname !== "/contact-us" && (
              <div className="w-[200px] mt-4" data-aos="fade-right">
                <div
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                  className="cursor-pointer hover:opacity-80 transition bg-[#da951f] text-white text-[1rem] font-semibold h-[50px] flex items-center justify-center rounded-md"
                >
                  Liên hệ chúng tôi
                </div>
              </div>
            )}
          </div>

          <div className="w-full h-[35vh] bg-[rgb(0,0,0)] flex flex-col items-center justify-center">
            <div
              className="flex items-center justify-center"
              data-aos="fade-up"
            >
              <Image
                preview={false}
                src="/assets/imgs/logo/logo-okvip.png"
                width={80}
                height={"auto"}
                alt=""
              />
              <p className="text-[2rem] font-bold text-it">IT</p>
            </div>
            <div className="flex flex-col justify-center items-center border-b-[1px]">
              <p
                className="font-medium text-[1rem]"
                data-aos="fade-down"
                data-aos-anchor-placement="bottom-bottom"
              >
                Kết nối với chúng tôi
              </p>
              <div className="my-4">
                <div
                  className="grid grid-cols-4 grid-rows-1 gap-2 px-3"
                  data-aos="fade-down"
                >
                  <Image
                    className="cursor-pointer hover:opacity-80 transition bg-white rounded-lg"
                    width={50}
                    preview={false}
                    src="/assets/imgs/icons/cskh.png"
                  />
                  <Image
                    className="cursor-pointer hover:opacity-80 transition bg-white rounded-lg"
                    width={50}
                    preview={false}
                    src="/assets/imgs/icons/zalo.png"
                  />
                  <Image
                    className="cursor-pointer hover:opacity-80 transition bg-white rounded-lg"
                    width={50}
                    preview={false}
                    src="/assets/imgs/icons/fb.png"
                  />
                  <Image
                    className="cursor-pointer hover:opacity-80 transition bg-white rounded-lg"
                    width={50}
                    preview={false}
                    src="/assets/imgs/icons/tele.png"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center py-3 bg-[rgb(0,0,0)]">
              <p className="font-medium text-[0.9rem]">
                Copyright © 2024 OKVIP.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-white border-t-[1px] border-white">
          <div className="page-bg w-full h-[22vh] flex items-center justify-between container mx-auto">
            <div className="w-[900px] font-bold text-white text-[2rem]">
              Hãy để chúng tôi đồng hành cùng bạn trên hành trình chuyển đổi kỹ
              thuật số của bạn.
            </div>
            {location?.pathname !== "/contact-us" && (
              <div className="w-[300px]">
                <div
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                  className="cursor-pointer hover:opacity-80 transition bg-[#da951f] text-white text-[1.1rem] font-semibold h-[50px] flex items-center justify-center rounded-md"
                >
                  Liên hệ chúng tôi
                </div>
              </div>
            )}
          </div>

          <div className="w-full h-[15vh] flex bg-[rgb(0,0,0)] ">
            <div className="container mx-auto flex justify-between items-center border-b-[1px] border-[#C7C7C9]">
              <div className="flex items-center">
                <div className="flex items-center">
                  <Image
                    preview={false}
                    src="/assets/imgs/logo/logo-okvip.png"
                    width={150}
                    height={"auto"}
                    alt=""
                  />
                  <p className="text-[3.3rem] font-bold text-it">IT</p>
                </div>
              </div>
              <div className="flex items-center">
                <p className="font-medium ml-10 text-[1.1rem]">
                  Kết nối với chúng tôi
                </p>
                <div>
                  <div className="grid grid-cols-4 grid-rows-1 gap-2 px-3">
                    <Image
                      className="cursor-pointer hover:opacity-80 transition bg-white rounded-lg"
                      width={50}
                      preview={false}
                      src="/assets/imgs/icons/cskh.png"
                    />
                    <Image
                      className="cursor-pointer hover:opacity-80 transition bg-white rounded-lg"
                      width={50}
                      preview={false}
                      src="/assets/imgs/icons/zalo.png"
                    />
                    <Image
                      className="cursor-pointer hover:opacity-80 transition bg-white rounded-lg"
                      width={50}
                      preview={false}
                      src="/assets/imgs/icons/fb.png"
                    />
                    <Image
                      className="cursor-pointer hover:opacity-80 transition bg-white rounded-lg"
                      width={50}
                      preview={false}
                      src="/assets/imgs/icons/tele.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center py-3 bg-[rgb(0,0,0)] ">
            <p className="font-medium text-[1.1rem]">Copyright © 2024 OKVIP.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
