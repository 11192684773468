import {
  Carousel,
  Image,
  Input,
  notification,
  Form,
  Button,
} from "antd";
import Aos from "aos";
import { useEffect } from "react";
import { MailIcon } from "../../utils/common";
import ContactCard from "../../components/Contact/ContactCard";
import Title from "../../components/Contact/Title";

import { useMediaQuery } from "react-responsive";
import "aos/dist/aos.css";
import TextArea from "antd/es/input/TextArea";
import "./styles.css";
const ContactPage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 425px)" });
  const [form] = Form.useForm();
  console.log(form);
  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);

  const onSubmit = (values) => {
    const { email, description } = values;
    if (email && description) {
      notification.success({
        description: `Chúng tôi sẽ trả lời cho bạn qua mail ${email}`,
        message: "GỬI PHẢN HỒI THÀNH CÔNG",
        placement: "topRight",
      });
    }
    form.resetFields();
  };
  //   onClick={() => {
  //     notification.success({
  //       description: "Chúng tôi sẽ trả lời ở email",
  //       message: "PHẢN HỒI GỬI THÀNH CÔNG",
  //       placement: "topRight",
  //     });
  //   }}
  return (
    <div className="w-full h-full">
      <div className="pt-24">
        <Carousel>
          <div className="relative">
            <Image
              style={{ objectFit: "cover" }}
              preview={false}
              height={500}
              width={"100%"}
              src={
                "https://fptsoftware.com/-/media/project/fpt-software/fso/contact-us/banner-sub-page-img.png?h=500&iar=0&w=1440&extension=webp&modified=20230811081542&hash=289300FC011F2BC4AB8FD35D405EB613"
              }
            />
            <span className="text-white absolute top-[50%] left-[10%] text-4xl font-bold">
              Liên hệ chúng tôi
            </span>
          </div>
        </Carousel>
        <div>
          {isMobile ? (
            <div className="bg-white text-black gap-x-8 container mx-auto w-full overflow-hidden">
              <div className="bg-[rgb(255,255,255,10%)] text-white pb-3">
                <p
                  className="text-[1.1rem] text-center px-8 py-6 font-bold uppercase text-black"
                  data-aos="fade-up"
                >
                  Liên hệ chúng tôi
                </p>
                <div data-aos="fade-down">
                  <Image
                    preview={false}
                    src="/assets/imgs/banner-footer.png"
                    alt=""
                    width={"auto"}
                  />
                </div>
                <div className="px-3 text-black">
                  <p
                    className="text-[1.2rem] font-semibold my-5"
                    data-aos="fade-left"
                  >
                    Nếu bạn không thể truy cập OKVIP
                  </p>
                  <p
                    className="text-[0.9rem] font-semibold my-5"
                    data-aos="fade-right"
                  >
                    Hãy liên hệ với chúng tôi qua email, chúng tôi sẽ cung cấp
                    cho bạn liên kết dự phòng mới nhất
                  </p>
                  <div
                    className="flex justify-between h-12 bg-[#424E84] rounded-md"
                    data-aos="fade-left"
                  >
                    <div className="flex items-center">
                      <div className="mx-5">
                        <MailIcon />
                      </div>
                      <span className="font-semibold text-[0.9rem] cursor-default text-white">
                        admin@okvip.com
                      </span>
                    </div>
                    <div className="text-white cursor-pointer hover:opacity-80 transition bg-[#2D9DEE] rounded-tr-md rounded-br-md flex items-center justify-center w-32 text-[0.9rem] font-semibold">
                      Gửi đi
                    </div>
                  </div>
                  <p
                    className="text-[#FF9900] text-[0.9rem] font-semibold my-5"
                    data-aos="fade-right"
                  >
                    Hãy liên hệ với chúng tôi bằng phương thức sau
                  </p>
                </div>
                <div
                  className="grid grid-cols-2 grid-rows-2 gap-2 px-3"
                  data-aos="fade-down"
                >
                  <ContactCard
                    bg={"bg-[#5FBE6E]"}
                    img={"/assets/imgs/icons/cskh.png"}
                    text={"CSKH Trực Tuyến"}
                  />
                  <ContactCard
                    bg={"bg-[#4D7CFE]"}
                    img={"/assets/imgs/icons/zalo.png"}
                    text={"Zalo"}
                  />
                  <ContactCard
                    bg={"bg-[#9476F4]"}
                    img={"/assets/imgs/icons/fb.png"}
                    text={"Facebook"}
                  />
                  <ContactCard
                    bg={"bg-[#2D9DEE]"}
                    img={"/assets/imgs/icons/tele.png"}
                    text={"Telegram"}
                  />
                </div>
              </div>
              <div className="flex-1 bg-black px-3 pb-3 text-white">
                <p
                  className="text-[1.1rem] text-center px-8 py-6 font-bold uppercase"
                  data-aos="fade-up"
                >
                  Câu hỏi
                </p>
                <p
                  className="text-[0.9rem] font-semibold text-center -mt-2"
                  data-aos="fade-down"
                >
                  Hãy đặt câu hỏi bên dưới
                </p>
                <Form
                  form={form}
                  layout="vertical"
                  className="text-white"
                  onFinish={onSubmit}
                >
                  <Form.Item
                    className="py-3"
                    label={<span className="text-white">Email</span>}
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Email không đúng định dạng",
                      },
                      {
                        required: true,
                        message: "Vui lòng nhập email!",
                      },
                    ]}
                  >
                    <Input
                      className="mb-2"
                      placeholder="*Hãy nhập địa chỉ mail"
                      style={{
                        background: "rgb(185 185 185 / 30%)",
                        outline: "none",
                        border: "none",
                        color: "rgb(255 255 255 / 60%)",
                        height: "60px",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        borderRadius: "10px",
                        padding: "0 20px",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span className="text-white">Nội dung phản hồi</span>
                    }
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập nội dung phản hồi!",
                      },
                    ]}
                  >
                    <TextArea
                      className="mb-2"
                      placeholder="*Hãy nhập nội dung phản hồi"
                      autoSize={{
                        minRows: 4,
                        maxRows: 4,
                      }}
                      style={{
                        background: "rgb(185 185 185 / 30%)",
                        outline: "none",
                        border: "none",
                        color: "rgb(255 255 255 / 60%)",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        borderRadius: "10px",
                        padding: "10px 20px",
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      data-aos="fade-up"
                      className="btn_submit_form border-none w-full h-14 mt-3 text-white bg-[#4D7CFE]  rounded-lg cursor-pointer hover:opacity-80 transition flex items-center justify-center text-[1rem] font-semibold"
                    >
                      Gửi phản hồi
                    </Button>
                  </Form.Item>
                </Form>

                <div
                  data-aos="fade-down"
                  className="text-[#9ba4d7] justify-normal text-[0.75rem] font-bold leading-[26px] mt-3"
                >
                  <p>
                    Cơ chế mở chính là kim chỉ nam trong định hướng phát triển
                    của Liên minh OKVIP. Với phương châm lấy khách hàng làm
                    trung tâm và coi sự hài lòng của khách là thước đo thành
                    công. Chúng tôi mong muốn cơ chế mở đến được với mỗi khách
                    hàng.
                  </p>
                  <p>
                    Mục <span className="text-[#FF9900]">CÂU HỎI</span> được xây
                    dựng dựa trên ý tưởng này.
                  </p>
                  <p>
                    Lắng nghe để phát triển, trân trọng từng ý kiến của khách
                    hàng.
                  </p>
                  <p>
                    Hệ thống sẽ dành tặng nhiều phần thưởng bí ẩn để tri ân
                    những quý khách hàng đã luôn tin tưởng đồng hành và đóng góp
                    ý kiến quý giá để OKVIP không ngừng hoàn thiện.
                  </p>
                  <p>
                    Hoan nghênh bạn biểu đạt ý kiến để giành nhiều phần thưởng
                    giá trị.
                  </p>
                  <p>Liên Minh OKVIP trân trọng cám ơn Quý Hội Viên !</p>
                </div>
              </div>
            </div>
          ) : (
            // DESKTOP
            <div className="h-full mx-auto  page-bg text-white py-10">
              <p
                className="text-[2rem] font-bold text-center pb-10 uppercase"
                data-aos="fade-up"
              >
                THÊM CÂU HỎI VÀ THÔNG TIN LIÊN HỆ ĐẾN CHÚNG TÔI
              </p>
              <div
                className="container grid grid-cols-2 gap-6 mx-auto"
                data-aos="fade-down"
              >
                <div className="bg-[rgb(255,255,255,10%)] h-full px-10 py-5 text-white">
                  <Title className="pb-4 pt-2">liên hệ chúng tôi</Title>
                  <div>
                    <Image
                      preview={false}
                      src="/assets/imgs/banner-footer.png"
                      alt=""
                      width={"auto"}
                    />
                  </div>
                  <p className="text-[1.4rem] font-semibold my-5">
                    Nếu bạn không thể truy cập OKVIP
                  </p>
                  <p className="text-[0.9rem] font-semibold my-5">
                    Hãy liên hệ với chúng tôi qua email, chúng tôi sẽ cung cấp
                    cho bạn liên kết dự phòng mới nhất
                  </p>
                  <div className="flex justify-between h-12 bg-[#424E84] w-[60%] rounded-md">
                    <div className="flex items-center">
                      <div className="mx-5">
                        <MailIcon />
                      </div>
                      <span className="font-semibold text-[0.9rem] cursor-default">
                        admin@okvip.com
                      </span>
                    </div>
                    <div className="cursor-pointer hover:opacity-80 transition bg-[#2D9DEE] rounded-tr-md rounded-br-md flex items-center justify-center w-32 text-[0.9rem] font-semibold">
                      Gửi đi
                    </div>
                  </div>
                  <p className="text-[#FF9900] text-[0.9rem] font-semibold my-5">
                    Hãy liên hệ với chúng tôi bằng phương thức sau
                  </p>
                  <div className="grid grid-flow-col grid-cols-4 gap-4">
                    <ContactCard
                      bg={"bg-[#5FBE6E]"}
                      img={"/assets/imgs/icons/cskh.png"}
                      text={"CSKH Trực Tuyến"}
                    />
                    <ContactCard
                      bg={"bg-[#4D7CFE]"}
                      img={"/assets/imgs/icons/zalo.png"}
                      text={"Zalo"}
                    />
                    <ContactCard
                      bg={"bg-[#9476F4]"}
                      img={"/assets/imgs/icons/fb.png"}
                      text={"Facebook"}
                    />
                    <ContactCard
                      bg={"bg-[#2D9DEE]"}
                      img={"/assets/imgs/icons/tele.png"}
                      text={"Telegram"}
                    />
                  </div>
                </div>
                <div className="flex-1 bg-[rgb(255,255,255,10%)] px-7 py-5 text-white">
                  <Title className="pb-4 pt-2">Câu hỏi</Title>
                  <p className="text-[0.9rem] font-semibold -mt-2">
                    Hãy liên hệ bên dưới
                  </p>
                  <Form
                    form={form}
                    layout="vertical"
                    className="text-white"
                    onFinish={onSubmit}
                  >
                    <Form.Item
                      className="py-3"
                      label={<span className="text-white">Email</span>}
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Email không đúng định dạng",
                        },
                        {
                          required: true,
                          message: "Vui lòng nhập email!",
                        },
                      ]}
                    >
                      <Input
                        className="mb-2"
                        placeholder="*Hãy nhập địa chỉ mail"
                        style={{
                          background: "rgb(185 185 185 / 30%)",
                          outline: "none",
                          border: "none",
                          color: "rgb(255 255 255 / 60%)",
                          height: "60px",
                          fontSize: "1rem",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          padding: "0 20px",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <span className="text-white">Nội dung phản hồi</span>
                      }
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập nội dung phản hồi!",
                        },
                      ]}
                    >
                      <TextArea
                        className="mb-2"
                        placeholder="*Hãy nhập nội dung phản hồi"
                        autoSize={{
                          minRows: 4,
                          maxRows: 4,
                        }}
                        style={{
                          background: "rgb(185 185 185 / 30%)",
                          outline: "none",
                          border: "none",
                          color: "rgb(255 255 255 / 60%)",
                          fontSize: "1rem",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          padding: "10px 20px",
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        data-aos="fade-up"
                        className="btn_submit_form border-none w-full h-14 mt-3 text-white bg-[#4D7CFE]  rounded-lg cursor-pointer hover:opacity-80 transition flex items-center justify-center text-[1rem] font-semibold"
                      >
                        Gửi phản hồi
                      </Button>
                    </Form.Item>
                  </Form>
                  <div className="text-[#9ba4d7] justify-normal text-[0.75rem] font-bold leading-[26px] mt-3">
                    <p>
                      Cơ chế mở chính là kim chỉ nam trong định hướng phát triển
                      của Liên minh OKVIP. Với phương châm lấy khách hàng làm
                      trung tâm và coi sự hài lòng của khách là thước đo thành
                      công. Chúng tôi mong muốn cơ chế mở đến được với mỗi khách
                      hàng.
                    </p>
                    <p>
                      Mục <span className="text-[#FF9900]">PHẢN HỒI</span> được
                      xây dựng dựa trên ý tưởng này.
                    </p>
                    <p>
                      Lắng nghe để phát triển, trân trọng từng ý kiến của khách
                      hàng.
                    </p>
                    <p>
                      Hệ thống sẽ dành tặng nhiều phần thưởng bí ẩn để tri ân
                      những quý khách hàng đã luôn tin tưởng đồng hành và đóng
                      góp ý kiến quý giá để OKVIP không ngừng hoàn thiện.
                    </p>
                    <p>
                      Hoan nghênh bạn biểu đạt ý kiến để giành nhiều phần thưởng
                      giá trị.
                    </p>
                    <p>Liên Minh OKVIP trân trọng cám ơn Quý Hội Viên !</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
