import ValuesCard from "./ValuesCard";
import { useEffect, useState } from "react";
import { Carousel, Image, Progress } from "antd";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import Aos from "aos";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

const OurValues = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 425px)" });
  const cards = [
    {
      img: "https://fptsoftware.com/-/media/project/fpt-software/fso/home/our-value/blue-inactive.png?extension=webp&modified=20230406103754",
      imgActive:
        "https://fptsoftware.com/-/media/project/fpt-software/fso/home/our-value/blue.png?extension=webp&modified=20230406103755",
      title: "Một team",
      description:
        "Chúng tôi tin rằng làm việc cùng nhau luôn có thể mang lại sự xuất sắc về mặt kỹ thuật số",
    },
    {
      img: "https://fptsoftware.com/-/media/project/fpt-software/fso/home/our-value/green-inactive.png?extension=webp&modified=20230406103755",
      imgActive:
        "https://fptsoftware.com/-/media/project/fpt-software/fso/home/our-value/green.png?extension=webp&modified=20230406103754",
      title: "Sự đổi mới",
      description:
        "Chúng tôi tìm kiếm các giải pháp sáng tạo để vượt qua mọi thách thức kinh doanh",
    },
    {
      img: "https://fptsoftware.com/-/media/project/fpt-software/fso/home/our-value/orange-inactive.png?extension=webp&modified=20230406103755",
      imgActive:
        "https://fptsoftware.com/-/media/project/fpt-software/fso/home/our-value/orange.png?extension=webp&modified=20230406103755",
      title: "Nhanh nhẹn",
      description: "Chúng tôi vẫn linh hoạt để dẫn đầu đối thủ",
    },
    {
      img: "https://fptsoftware.com/-/media/project/fpt-software/fso/home/our-value/sky-inactive.png?extension=webp&modified=20230406103753",
      imgActive:
        "https://fptsoftware.com/-/media/project/fpt-software/fso/home/our-value/sky.png?extension=webp&modified=20230406103754",
      title: "Sự cam kết",
      description:
        "Chúng tôi được giao phó mang lại tác động tích cực cho khách hàng và cộng đồng",
    },
    {
      img: "https://fptsoftware.com/-/media/project/fpt-software/fso/home/our-value/purple-inactive.png?extension=webp&modified=20230406103754",
      imgActive:
        "https://fptsoftware.com/-/media/project/fpt-software/fso/home/our-value/purple.png?extension=webp&modified=20230406103755",
      title: "Sự tôn trọng",
      description:
        "Chúng tôi đánh giá cao ý tưởng và niềm tin của mỗi bên liên quan",
    },
  ];
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItemIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {isMobile ? (
        <div className="w-full bg-white pt-10 pb-10">
          <div className="text-center px-5">
            <p className="text-[#696b70] text-[1.1rem]" data-aos="fade-down">
              GIÁ TRỊ CỦA CHÚNG TÔI
            </p>
            <p
              className="text-[2.3rem] font-bold text-[#ffb029]"
              data-aos="zoom-in"
            >
              Những giá trị chúng tôi đại diện
            </p>
            <p className="text-[1.1rem] text-black" data-aos="fade-up">
              Đây là những gì chúng ta sống và cách chúng ta nói chuyện trong
              cuộc sống công việc hàng ngày. Chúng thể hiện cam kết của chúng
              tôi với con người, khách hàng, đối tác và các bên liên quan của
              chúng tôi
            </p>
          </div>
          <Swiper
            data-aos="zoom-in"
            className="flex justify-center items-center mt-10"
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
          >
            {cards.map((item, index) => (
              <SwiperSlide className="w-full text-center">
                <p className="font-bold text-[1.1rem] ">{item.title}</p>
                <p className="text-[1.1rem] px-12">{item.description}</p>
                <Image
                  preview={false}
                  src={item.imgActive}
                  alt=""
                  width={100}
                  height={100}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className="w-full h-[80vh] bg-white">
          <div className="h-full container mx-auto pt-24">
            <div className="text-center">
              <p className="text-[#696b70] text-[1.3rem]" data-aos="fade-down">
                GIÁ TRỊ CỦA CHÚNG TÔI
              </p>
              <p
                className="text-[2.7rem] font-bold text-[#ffb029] py-2"
                data-aos="zoom-in"
              >
                Những giá trị chúng tôi đại diện
              </p>
              <p className="text-[1.25rem] mx-[500px]" data-aos="fade-up">
                Đây là những gì chúng ta sống và cách chúng ta nói chuyện trong
                cuộc sống công việc hàng ngày. Chúng thể hiện cam kết của chúng
                tôi với con người, khách hàng, đối tác và các bên liên quan của
                chúng tôi
              </p>
            </div>
            <div className="grid grid-cols-5 gap-8 mt-24" data-aos="zoom-in">
              {cards.map((item, index) => (
                <ValuesCard
                  index={index}
                  item={item}
                  key={index}
                  isActive={index === currentItemIndex}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OurValues;
