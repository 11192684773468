export const optionsMenu=[
    {
        value:"/",
        label:"Trang chủ",
    },
    {
        value:"/projects",
        label:"Dự án",

    },
    {
        value:"/contact-us",
        label:"Liên hệ"
    }
]