import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import router from "./routers/sections";
import { useEffect, useState } from "react";
import Loading from "./components/Loading";
import { RecoilRoot } from "recoil";
const App = () => {
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    console.log(
      '%cDeveloped by 👌OKVIP',
      'padding: 0.3rem 1.5rem; font-family: Roboto; font-size: 1.2em; line-height: 1.4em; color: white; background: rgb(131,58,180); background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);'
    );
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div className="App">
      {loading ? (
        <Loading />
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Segmented: {
                itemSelectedBg: "none",
                itemSelectedColor: "#ffb029",
                itemHoverBg: "none",
                itemHoverColor: "#ffb029",
                fontSize: 18,
                itemColor: "white",
                itemActiveBg: "none",
              },
              Tabs: {
                itemColor: "#b7b7b7",
                itemHoverColor: "#ffb029",
                itemSelectedColor: "#ffb029",
                inkBarColor: "#ffb029",
                titleFontSize: "20px",
              },
              Menu: {
                itemBg: "none",
                itemColor: "#b7b7b7",
                itemHoverColor: "#ffb029",
                itemSelectedColor: "#ffb029",
                inkBarColor: "#ffb029",
                titleFontSize: "20px",
              },
              Button: {
                colorPrimaryHover: "#ffb029",
                colorBgBase: "#ffb029",
              },
            },
          }}
        >
          <RecoilRoot>
            <RouterProvider router={router} />
          </RecoilRoot>
        </ConfigProvider>
      )}
    </div>
  );
};

export default App;
