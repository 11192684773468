import React, { Fragment, useEffect, useState } from "react";
import { Card, ConfigProvider, Image, List, Skeleton, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Aos from "aos";
const { Meta } = Card;
const ProjectPage = () => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);

  useEffect(() => {
    setLoading(true);
    const APIURL = process.env.REACT_APP_API_URL;
    fetch(`${APIURL}/project/getAllProjects`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data?.code === 200) {
          for (let index = 0; index < 10; index++) {
            data.data.push(data?.data[index]);
          }
          setData(data?.data);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          navigate("/error");
        }
        throw new Error(error);
      });
  }, [navigate]);
  const renderListItem = (item) => {
    return (
      <List.Item style={{ padding: isMobile ? 15 : 0 }}>
        <Card
          loading={loading}
          className="h-[350px] lg:h-[300px] md:h-[300px] card_project_description cursor-default hover:-translate-y-3 transition duration-500"
          hoverable
          cover={
            <div className="w-full h-full card_image_cover overflow-hidden">
              {loading ? (
                <Skeleton.Image
                  className="card_project skeleton__image"
                  active
                />
              ) : (
                <Image
                  preview={true}
                  height={"100%"}
                  width={"100%"}
                  className="image_project object-cover hover:scale-110 rounded-md"
                  alt={item?.projectName + "Image"}
                  src={item?.projectImageUrl}
                />
              )}
            </div>
          }
        >
          <Meta
            title={
              <div className="flex items-center justify-between">
                <Typography.Text ellipsis={{ tooltip: true }}>
                  <Link
                    className="text-lg "
                    to={item?.projectUrl}
                    target="_blank"
                  >
                    {item?.projectName}
                  </Link>
                </Typography.Text>
                <Link
                  className="bg-yellow-400 hover:bg-yellow-500 px-1 rounded-md "
                  style={{ color: "white" }}
                  to={item?.projectUrl}
                  target="_blank"
                >
                  Xem chi tiết
                </Link>
              </div>
            }
            description={
              <Typography.Paragraph
                ellipsis={{
                  rows: 3,
                }}
              >
                {item?.projectDescription}
              </Typography.Paragraph>
            }
          />
        </Card>
      </List.Item>
    );
  };

  return (
    <div className="w-full h-full">
      <div className="w-full  container mx-auto pt-24">
        <Typography.Title
          style={{ color: "white", textAlign: "center" }}
          className="mt-4"
          level={2}
        >
          DANH SÁCH TẤT CẢ DỰ ÁN
          <br />
          <span className="font-semibold text-[#FFB009]">(Web/App)</span>
        </Typography.Title>

        <Fragment>
          <ConfigProvider
            renderEmpty={() => {
              return <></>;
            }}
            theme={{
              components: {
                List: {},
                Card: {
                  paddingLG: 12,
                },
              },
            }}
          >
            {isDesktop && (
              <List
                data-aos="fade-up"
                grid={{ column: 4, gutter: [25, 15] }}
                dataSource={data}
                renderItem={(item) => renderListItem(item)}
              />
            )}
            {isTablet && (
              <List
                grid={{ column: 2, gutter: 20 }}
                dataSource={data}
                renderItem={(item) => renderListItem(item)}
              />
            )}
            {isMobile && (
              <List
                grid={{ column: 1 }}
                dataSource={data}
                renderItem={(item) => renderListItem(item)}
              />
            )}
          </ConfigProvider>
        </Fragment>
      </div>
    </div>
  );
};

export default ProjectPage;
