import { createBrowserRouter } from "react-router-dom";
import ServerErrorPage from "../pages/Error/ServerError";
import NotFoundPage from "../pages/Error/NotFound";
import HomePage from "../pages/Home";
import BasicLayout from "../components/layouts/BasicLayout";
import ProjectPage from "../pages/Project";
import ContactPage from "../pages/Contact";
const router = createBrowserRouter([
  {
    path: "/",
    element: <BasicLayout />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/projects",
        element: <ProjectPage />,
      },
      {
        path: "/contact-us",
        element: <ContactPage />,
      },
    ],
  },
  { path: "/error", element: <ServerErrorPage /> },
]);

export default router;
