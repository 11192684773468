import { Button, ConfigProvider, Drawer, Image, Space } from "antd";
import "./styles.css";
import { optionsMenu } from "../../constant/constant_options_headers";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
const Header = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 425px)" });
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return isMobile ? (
    <div className="h-16 w-full fixed z-10 bg-none flex items-center bg-[rgba(0,0,0,0.5)] pl-4">
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <div
            onClick={() => {
              setTimeout(() => {
                navigate("/");
              }, 300);
            }}
            className="cursor-pointer"
          >
            <Image
              preview={false}
              src="/assets/imgs/logo/logo-okvip.png"
              width={100}
              height={"auto"}
              alt=""
            />
          </div>

          <p className="text-[2.3rem] font-bold text-it">IT</p>
        </div>
        <div className="nav_header flex  items-center">
          <ConfigProvider
            theme={{
              components: {
                Drawer: {
                  paddingLG: 0,
                },
              },
            }}
          >
            <MenuOutlined
              className={`text-white text-lg mr-6 ${
                open
                  ? "transform -translate-x-[100px] opacity-0 duration-300 "
                  : "transform translate-x-0 opacity-1 duration-300 "
              }`}
              onClick={showDrawer}
            />
            <Drawer
              className="header_drawer_mobile"
              placement={"left"}
              width={"100%"}
              open={open}
              closable={false}
              title={
                <Image
                onClick={() => {
                  setTimeout(() => {
                    navigate("/");
                    onClose()
                  }, 300);
                }}
                  className="ml-3"
                  preview={false}
                  src="/assets/imgs/logo/logo-okvip.png"
                  width={100}
                  height={"auto"}
                  alt=""
                />
              }
              extra={
                <Space>
                  <CloseOutlined
                    className="mr-4 font-semibold text-xl active:bg-[rgba(0,0,0,0.5)] p-1"
                    onClick={onClose}
                  />
                </Space>
              }
            >
              {optionsMenu.map((item) => (
                <div
                  onClick={() => {
                    setTimeout(() => {
                      navigate(item?.value);
                      onClose();
                    }, 300);
                  }}
                  className="p-4 border-b border-gray-500 "
                >
                  <p className="text-lg">{item?.label}</p>
                </div>
              ))}
            </Drawer>
          </ConfigProvider>
        </div>
      </div>
    </div>
  ) : (
    <div className="h-24 w-full  fixed z-10 bg-none flex items-center bg-[rgba(0,0,0,0.8)]">
      <div className="container mx-auto flex items-center justify-between">
        <div
          onClick={() => {
            setTimeout(() => {
              navigate("/");
              onClose();
            }, 300);
          }}
          className="flex items-center cursor-pointer"
        >
          <Image
            className="cursor-pointer"
            preview={false}
            src="/assets/imgs/logo/logo-okvip.png"
            width={150}
            height={55}
            alt=""
          />
          <p className="text-[3.5rem] font-bold text-it">IT</p>
        </div>
        <div className="nav_header">
          <div className="flex items-center">
            {optionsMenu.map((item) => {
              return (
                <div key={item?.value} className="mx-5">
                  <span
                    onClick={() => {
                      setTimeout(() => {
                        if (item?.value === "/") return navigate("/");
                        navigate(item?.value);
                      }, 300);
                    }}
                    className={` text-[18px] cursor-pointer hover:text-[#FFB009] duration-300 
                  ${
                    location?.pathname === item?.value
                      ? "text-[#FFB009]"
                      : "text-white"
                  }
                  `}
                  >
                    {item?.label}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
