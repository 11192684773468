import { Image } from 'antd'
import React from 'react'
const Loading = () => {
  return (
    <div style={{
      background:"#12141c radial-gradient(69.02% 82.31% at 51.56% 52.41%, #394186 0, rgba(11, 10, 18, .95) 65.63%)"
    }} className='w-full h-[100vh] flex justify-center items-center  p-0 m-0'>
          <Image preview={false} width={350} src="/assets/gifts/loading.gif" />
    </div>
  )
}

export default Loading
