import { Card, Image, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Grid, Navigation } from "swiper/modules";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import Link from "antd/es/typography/Link";
import { useMediaQuery } from "react-responsive";
import Aos from "aos";
const { Meta } = Card;

const ProjectSection = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 425px)" });
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);

  useEffect(() => {
    const APIURL = process.env.REACT_APP_API_URL;
    fetch(`${APIURL}/project/getAllProjects`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setData(data?.data);
      })
      .catch((error) => {
        if (error) {
          navigate("/error");
        }
        throw new Error(error);
      });
  }, []);

  return (
    <div className="w-full page-bg">
      {isMobile ? (
        <div className="bg-black text-white">
          <p
            className="text-[1.1rem] text-center px-8 py-6 font-bold"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          >
            HÃY CÙNG KHÁM PHÁ NHỮNG DỰ ÁN CỦA CHÚNG TÔI (WEB/APP)
          </p>
          <Swiper
            slidesPerView={1}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
            }}
            navigation={{ enabled: true }}
            modules={[Navigation, Autoplay]}
            data-aos="fade-down"
          >
            {(data || []).map((item, index) => (
              <SwiperSlide className="px-3 pb-3">
                <Card
                  className="shadow-md"
                  hoverable={true}
                  cover={
                    <Image
                      style={{
                        maxWidth: "100%",
                        maxHeight: "10rem",
                        minHeight: "10rem",
                      }}
                      alt={item?.projectName + "Image"}
                      src={item?.projectImageUrl}
                    />
                  }
                >
                  <Meta
                    title={
                      <div className="flex items-center justify-between">
                        <Link
                          className="text-[20px] "
                          href={item?.projectUrl}
                          target="_blank"
                        >
                          <Typography.Text
                            className="text-[20px] text-[#FFB009] hover:text-[#ffb109b9]"
                            ellipsis={{ tooltip: true }}
                          >
                            {item?.projectName}
                          </Typography.Text>
                        </Link>

                        <Link
                          className="bg-yellow-400 hover:bg-yellow-500  p-1 rounded-md "
                          style={{ color: "white" }}
                          href={item?.projectUrl}
                          target="_blank"
                        >
                          Xem chi tiết
                        </Link>
                      </div>
                    }
                    description={
                      <div className="project_description line-clamp-5">
                        <Typography.Paragraph ellipsis={{ rows: 6 }}>
                          {item?.projectDescription}
                        </Typography.Paragraph>
                      </div>
                    }
                  />
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className="container mx-auto pb-20">
          <div>
            <p
              className="text-[2rem] font-bold text-center py-20 text-white"
              data-aos="fade-up"
            >
              HÃY CÙNG KHÁM PHÁ NHỮNG DỰ ÁN CỦA CHÚNG TÔI (WEB/APP)
            </p>
          </div>

          <div className="w-full h-[80%] flex items-center">
            <Swiper
              data-aos="fade-down"
              loop={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: true,
              }}
              style={{ width: "100%", paddingTop: 70 }}
              spaceBetween={30}
              slidesPerView={2.5}
              grid={{
                fill: "row",
              }}
              navigation={{
                enabled: true,
                nextEl: ".next",
                prevEl: ".prev",
              }}
              modules={[Autoplay, Navigation, Grid]}
              className="project_swiper"
            >
              {(data || []).map((item, index) => {
                return (
                  <SwiperSlide key={item?._id}>
                    <Card
                      className="project_item shadow-md hover:-translate-y-5 transition  duration-300"
                      hoverable={true}
                      cover={
                        <Image
                          style={{
                            maxWidth: "100%",
                            maxHeight: "15rem",
                            minHeight: "15rem",
                          }}
                          alt={item?.projectName + "Image"}
                          src={item?.projectImageUrl}
                        />
                      }
                    >
                      <Meta
                        title={
                          <div className="flex items-center justify-between">
                            <div className="w-[82%]">
                              <Link
                                className=" "
                                href={item?.projectUrl}
                                target="_blank"
                              >
                                <Typography.Text
                                  className="text-[20px] text-[#FFB009] hover:text-[#ffb109b9]"
                                  ellipsis={{ tooltip: true }}
                                >
                                  {item?.projectName}
                                </Typography.Text>
                              </Link>
                            </div>

                            <Link
                              className="bg-yellow-400 hover:bg-yellow-500  p-1 rounded-md "
                              style={{ color: "white" }}
                              href={item?.projectUrl}
                              target="_blank"
                            >
                              Xem chi tiết
                            </Link>
                          </div>
                        }
                        description={
                          <div className="project_description">
                            <Typography.Paragraph ellipsis={{ rows: 6 }}>
                              {item?.projectDescription}
                            </Typography.Paragraph>
                          </div>
                        }
                      />
                    </Card>
                  </SwiperSlide>
                );
              })}
              <div className="absolute top-1 w-24  flex justify-between items-center  text-[25px]">
                <div className="prev prev_custom_project bg-[#e4e5e6] z-10 cursor-pointer rounded-md px-2">
                  <LeftOutlined />
                </div>
                <div className="next next_custom_project bg-[#e4e5e6] z-10 cursor-pointer  rounded-md px-2">
                  <RightOutlined />
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectSection;
