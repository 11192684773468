import { Carousel, Image } from "antd";
import { Fragment, useEffect, useRef, useState } from "react";
import "./styles.css";
import Aos from "aos";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
const HomeCarousel = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 425px)" });

  const [data, setData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const ref = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const APIURL = process.env.REACT_APP_API_URL;
    fetch(`${APIURL}/banner/getListBanner`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setData(data?.data);
      })
      .catch((error) => {
        console.log("error", error);
        if (error) {
          navigate("/error");
        }
        throw new Error(error);
      });
  }, [navigate]);

  const dataBannerMobile = [];
  const dataBannerPC = [];
  data.forEach(
    (el) => el.bannerName === "Banner1" && dataBannerMobile.push(el)
  );
  data.forEach((el) => el.bannerMobile && dataBannerMobile.push(el));
  data.forEach((el) => !el.bannerMobile && dataBannerPC.push(el));
  return (
    <div>
      {isMobile ? (
        <Fragment>
          <Carousel
            draggable
            afterChange={(e) => {
              setCurrentSlide(e);
            }}
            dots={false}
            ref={ref}
            autoplay
            autoplaySpeed={4000}
            dotPosition="bottom"
          >
            {(dataBannerMobile || []).map((item, index) => {
              return (
                <div
                  key={item?._id}
                  className="relative overflow-hidden h-screen"
                >
                  {item?.bannerVideoUrl?.endsWith(".mp4") ? (
                    <div className="h-screen">
                      <video
                        autoPlay
                        muted
                        loop
                        style={{
                          height: "100vh",
                          objectFit: "cover",
                        }}
                      >
                        <source src={item?.bannerVideoUrl} type="video/mp4" />
                      </video>
                    </div>
                  ) : (
                    <Image
                      preview={false}
                      src={item?.bannerVideoUrl}
                      width={"100%"}
                      height={"100vh"}
                    />
                  )}

                  <div className="absolute bottom-[100px] left-3 px-4 z-[1000] text-white leading-9">
                    <p
                      className="text-[0.9rem] font-medium"
                      data-aos="fade-down "
                    >
                      {item?.bannerTitleDescription}
                    </p>
                    <p
                      className="text-[1.8rem] font-bold text-glow"
                      data-aos="fade-right"
                    >
                      {item?.bannerDescription}
                    </p>
                  </div>
                </div>
              );
            })}
          </Carousel>
          <div className="w-full absolute bottom-5 ">
            <div className="flex justify-evenly">
              {(dataBannerMobile || []).map((item, index) => {
                return (
                  <div
                    key={item?._id}
                    className={`${
                      currentSlide === index
                        ? "text_title_tab_active border-b-2 border-[#FFB009] pb-2 text-[#FFB009]"
                        : "border-b-2 border-gray-300 text-white"
                    }  cursor-pointer text-[16px] w-[20%] font-semibold`}
                    onClick={() => {
                      ref?.current?.goTo(index, false);
                    }}
                  >
                    {/* <span>{item?.bannerTitleTab}</span> */}
                  </div>
                );
              })}
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <Carousel
            draggable
            afterChange={(e) => {
              setCurrentSlide(e);
            }}
            dots={false}
            ref={ref}
            autoplay
            autoplaySpeed={4000}
            dotPosition="bottom"
          >
            {(dataBannerPC || []).map((item) => {
              return (
                <div
                  key={item?._id}
                  className="relative overflow-hidden h-screen"
                >
                  {item?.bannerVideoUrl?.endsWith(".mp4") ? (
                    <video autoPlay muted loop>
                      <source src={item?.bannerVideoUrl} type="video/mp4" />
                    </video>
                  ) : (
                    <Image
                      preview={false}
                      src={item?.bannerVideoUrl}
                      width={"100%"}
                      height={"100%"}
                    />
                  )}

                  <div className="absolute bottom-[380px] left-[250px] z-[1000] text-white">
                    <div className="w-[55%]">
                      <p
                        className="text-[1.8rem] font-medium"
                        data-aos="fade-down"
                      >
                        {item?.bannerTitleDescription}
                      </p>
                      <p
                        className="text-[3.7rem] font-bold text-glow"
                        data-aos="fade-right"
                      >
                        {item?.bannerDescription}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
          <div className="w-full absolute bottom-20 ">
            <div className="flex justify-evenly">
              {(dataBannerPC || []).map((item, index) => {
                return (
                  <div
                    key={item?._id}
                    className={`${
                      currentSlide === index
                        ? "text_title_tab_active border-b-2 border-[#FFB009] pb-2 text-[#FFB009]"
                        : "border-b-2 border-gray-300 text-white"
                    }  cursor-pointer text-[16px] w-[20%] font-semibold`}
                    onClick={() => {
                      ref?.current?.goTo(index, false);
                    }}
                  >
                    <span>{item?.bannerTitleTab}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default HomeCarousel;
