import { Image } from "antd";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import "./styles.css";

const ValuesCard = ({ item, isActive, index }) => {
  return (
    <div className="w-full text-center relative">
      <div className="w-[120px] h-[120px] mx-auto">
        <Image
          preview={false}
          src={isActive ? item.imgActive : item.img}
          alt=""
          width={100}
          height={100}
        />
      </div>
      <div className="w-full h-[2px] bg-[#bebec0] my-4"></div>
      {isActive && (
        <div className="progress-bar-container absolute top-[136px]">
          <div
            className={twMerge(
              "progress-bar",
              index === 0
                ? "bg-[#034EA2]"
                : index === 1
                ? "bg-[#51B747]"
                : index === 2
                ? "bg-[#F37021]"
                : index === 3
                ? "bg-[#33B3C0]"
                : "bg-[#19226C]"
            )}
          ></div>
        </div>
      )}
      <div className={isActive ? "text-black" : "text-[#7c7c88]"}>
        <p className="font-bold text-[1.1rem] mb-1">{item.title}</p>
        <p className="text-[1.1rem]">{item.description}</p>
      </div>
    </div>
  );
};

export default ValuesCard;
