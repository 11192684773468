import { Button, Flex, Typography } from "antd";
;

export default function NotFoundPage() {
  return (
    <Flex
      justify="center"
      wrap="wrap"
      align="center"
      className="w-full h-full p-[10vh]"
    >
      <img alt="error" src={"/assets/imgs/errors/not-found.webp"} />
      <Flex
        vertical
        align="flex-end"
        justify="space-between"
        className="p-[32px]"
      >
        <Typography.Title level={3}>
          <span className="text-red-500">Không tìm thấy trang này!</span>
        </Typography.Title>
        <Button
          type="primary"
          onClick={() => window.location.replace("/")}
          className="bg-red-500 font-semibold hover:bg-red-400"
        >
          Về Trang Chủ
        </Button>
      </Flex>
    </Flex>
  );
}
