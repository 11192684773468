import React, { Fragment } from "react";
import HomeCarousel from "../../components/Home";
import ProjectSection from "../../components/Project";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";
import OurValues from "../../components/OurValues";

const HomePage = () => {
  return (
    <Fragment>
      {/* Section carousel */}
      <HomeCarousel />
      {/* Section Project */}
      <ProjectSection />

      <OurValues />
    </Fragment>
  );
};

export default HomePage;
