export const MailIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_261_4344)">
        <g filter="url(#filter0_d_261_4344)">
          <path
            d="M10.9383 4.875H4.875V13.8836L0.0101563 10.2832C0.0914062 9.36406 0.563672 8.51094 1.31523 7.95742L2.4375 7.12461V4.875C2.4375 3.5293 3.5293 2.4375 4.875 2.4375H8.76484L11.2988 0.563672C11.7914 0.198047 12.3855 0 13 0C13.6145 0 14.2086 0.198047 14.7012 0.558594L17.2352 2.4375H21.125C22.4707 2.4375 23.5625 3.5293 23.5625 4.875V7.12461L24.6848 7.95742C25.4363 8.51094 25.9086 9.36406 25.9898 10.2832L21.125 13.8836V4.875H10.9383ZM0 22.75V12.2941L11.05 20.4801C11.6137 20.8965 12.2992 21.125 13 21.125C13.7008 21.125 14.3863 20.9016 14.95 20.4801L26 12.2941V22.75C26 24.5426 24.5426 26 22.75 26H3.25C1.45742 26 0 24.5426 0 22.75ZM8.9375 8.125H17.0625C17.5094 8.125 17.875 8.49063 17.875 8.9375C17.875 9.38437 17.5094 9.75 17.0625 9.75H8.9375C8.49063 9.75 8.125 9.38437 8.125 8.9375C8.125 8.49063 8.49063 8.125 8.9375 8.125ZM8.9375 11.375H17.0625C17.5094 11.375 17.875 11.7406 17.875 12.1875C17.875 12.6344 17.5094 13 17.0625 13H8.9375C8.49063 13 8.125 12.6344 8.125 12.1875C8.125 11.7406 8.49063 11.375 8.9375 11.375Z"
            fill="white"
            fillOpacity="0.85"
            shapeRendering="crispEdges"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_261_4344"
          x="-2"
          y="0"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          ></feColorMatrix>
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_261_4344"
          ></feBlend>
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_261_4344"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_261_4344">
          <rect width="26" height="26" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};
