import { Button, Flex, Image, Typography } from "antd";
export default function ServerErrorPage() {
  return (
    <Flex
      justify="center"
      wrap="wrap"
      align="center"
      className="w-full h-full p-[10vh]"
    >
      <Image preview={false} alt="error" src="/assets/imgs/errors/server-error.png" />
      <Flex
        vertical
        align="flex-end"
        justify="space-between"
        className="p-[32px]"
      >
        <Typography.Title level={3}>
          <span className="text-red-500">Không thể truy cập đến máy chủ!</span>
        </Typography.Title>
        <Button
          type="primary"
          className="bg-red-500 hover:bg-red-400 font-semibold "
          onClick={() => window.location.replace("/")}
        >
          Về Trang Chủ
        </Button>
      </Flex>
    </Flex>
  );
}
